import { Injectable } from '@angular/core';
import { DataApiService, DataOptions, HttpOptions, BaseService } from '@xpo-ltl/data-api';
import { ConfigManagerService } from '@xpo-ltl/config-manager';

import {
	GetServiceCenterDetailsRqst,
	GetServiceCenterDetailsResp,
	GetServiceCenterDetailsBySicRqst,
	GetServiceCenterDetailsBySicResp,
	GetSicByFinanceCodesRqst,
	GetSicByFinanceCodesResp,
	ListPostalAssignmentsForSicRqst,
	ListPostalAssignmentsForSicResp,
	ListSicsWithPostalAssignmentChangesRqst,
	ListSicsWithPostalAssignmentChangesResp,
	ListSicsRqst,
	ListSicsResp,
	ListOperationalCalendarDaysRqst,
	ListOperationalCalendarDaysResp,
	GetNextBusinessDayRqst,
	GetNextBusinessDayResp,
	GetCustomerServiceCenterDetailsRqst,
	GetCustomerServiceCenterDetailsResp,
	ValidateAddressRqst,
	ValidateAddressResp,
	ValidateLtlFreightOperationsSicRqst,
	ValidateLtlFreightOperationsSicResp,
	GetLocReferenceDetailsBySicRqst,
	GetLocReferenceDetailsBySicResp,
	GetZoneAndSatelliteBySicRqst,
	GetZoneAndSatelliteBySicResp,
	IsPndAvailableRqst,
	IsPndAvailableResp,
	ListSicByPostalCdRqst,
	ListSicByPostalCdResp
} from './api-location';

import {
	GetHealthCheckResp,
	User
} from '@xpo-ltl/sdk-common';

import { Observable } from 'rxjs';

@Injectable()
export class LocationApiService extends BaseService {
	private static LocationApiEndpoint = 'locationApiEndpoint';

	constructor(private dataApiService: DataApiService, private configManager: ConfigManagerService) {
		super();
	}

	/**
	* Health check URL. Responds with success message if the service is running.
	*/
	public healthCheck(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetHealthCheckResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/health-check'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* List of resources.
	*/
	public options(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/options'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Details of user invoking the API.
	*/
	public loggedInUser(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<User> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/appusers/logged-in-user'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Returns location information about a service center. This information shall include the service center's physical address as well as geocoordinates for use in GIS applications.
	*/
	public getServiceCenterDetails(request: GetServiceCenterDetailsRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetServiceCenterDetailsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/service-centers'),
			{
				queryParams: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Returns location information about a service center. This information shall include the service center's physical address as well as geocoordinates for use in GIS applications.
	*/
	public getServiceCenterDetailsBySic(request: GetServiceCenterDetailsBySicRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetServiceCenterDetailsBySicResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/service-centers/{sicCd}'),
			{
		    	pathParams: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Returns book closing SIC (could be Service Center, Area or District) based on the Oracle Financial Triplet which consists of Company Code, Profit Center Code and Department Code.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. Must have a valid Oracle Financial Triplet.
	* <br/>
	* <br/>Post-condition:
	* <br/>1. If the Oracle Financial Triplet is valid, the SIC code and name that are associated with it will be returned.
	* <br/>2. If the Oracle Financial Triplet is invalid or there is no SIC code and name associated with it, an error will be returned.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. Input Oracle Financial Triplet supplied with Company Code of 4052.
	* <br/>
	* <br/>Post-condition:
	* <br/>1. Not found error is returned.
	* <br/>2. Retry the call with the same Input Oracle Financial Triplet but with a Company Code of 4051.
	*/
	public getSicByFinanceCodes(request: GetSicByFinanceCodesRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetSicByFinanceCodesResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/service-centers/{companyCd}/{profitCenterCd}/{departmentCd}'),
			{
		    	pathParams: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This service retrieves all postal codes assigned to a SIC.  A valid SIC must be provided.
	* <br/>Pre-conditions: 
	* <br/>	Valid input data - SIC
	* <br/>Post-conditions: 
	* <br/>	A list of postal codes will be returned.
	* <br/>			 
	*/
	public listPostalAssignmentsForSic(request: ListPostalAssignmentsForSicRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListPostalAssignmentsForSicResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/postalassignments/{sic}'),
			{
		    	pathParams: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* list SICs with postal assignment changes (either adds or deletes) for the week
	* <br/>pre-condition - valid SIC and valid effective date(mm/dd/yyyy). The effective date will be used minus one day to determine assignments that have expired.
	* <br/>post condition - list of SICs
	*/
	public listSicsWithPostalAssignmentChanges(request: ListSicsWithPostalAssignmentChangesRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListSicsWithPostalAssignmentChangesResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/postalassignment'),
			{
				queryParams: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* List locations using input filter criteria describing the desired location results.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>Relevant search criteria will be supplied.  
	* <br/>
	* <br/>Post-condition:
	* <br/>One or more location Sic codes with address details will be returned based on the input search criteria for locations with respective field equal to or like the input value.
	*/
	public listSics(request: ListSicsRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListSicsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/service-centers/sics'),
			{
				queryParams: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This service will retrieve the working or non-working daylist on the basis of given day.
	* <br/>Pre-conditions: 
	* <br/>1. Input data are valid. A valid start date is provided.
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists, user details are updated.
	*/
	public listOperationalCalendarDays(request: ListOperationalCalendarDaysRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListOperationalCalendarDaysResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/opscalendardays'),
			{
				queryParams: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This service will determine the next business day based on input date and offset number of days. The number of offset days are added to the start date and the next business day is determined after adjusting for weekends or holidays.
	* <br/>Pre-conditions: 
	* <br/>1. Input data are valid. A valid start date is provided.
	* <br/>Post-conditions: 
	* <br/>1. If Input data not valid/does not exist, error message is returned.
	* <br/>2. If Input data valid/exists, next business day is returned.
	* <br/>
	*/
	public getNextBusinessDay(request: GetNextBusinessDayRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetNextBusinessDayResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/nextbusinessday'),
			{
				queryParams: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* For XPO customer use. Returns information about the service center based on the sic code or services the area identified by a provided ZIP code. This information includes the service center's physical address and geo-coordinates for use in GIS applications as well as its contact information.
	*/
	public getCustomerServiceCenterDetails(request: GetCustomerServiceCenterDetailsRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetCustomerServiceCenterDetailsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/service-centers/customer'),
			{
				queryParams: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Validates the input address and if valid, returns a properly coded address.  Otherwise, returns appropriate error codes.
	* <br/>Pre-Condition: Mandatory address input is supplied and valid.
	* <br/>Post-Condition: Validated address is returned as a properly coded address.
	* <br/>
	* <br/>Pre-Condition: Mandatory address input is supplied and is not valid.
	* <br/>Post-Condition: Address validation result is returned with message indicating the error with the validated address.
	* <br/>
	* <br/>Possible validation results for invalid address: 
	* <br/>  RETURN REASON CODE SPECIFICATION                                     
	* <br/>  -20       2  Country Subdivision required                            
	* <br/>  -20       4  Postal Code required                                    
	* <br/>  -20       5  City name required                                      
	* <br/>  -20       6  Address 1 required                                      
	* <br/>  -20      10  Country code required                                   
	* <br/>  -21      30  Invalid Country Code                                    
	* <br/>  -21      33  City contains invalid characters                        
	* <br/>  -21      34  Postal Code Contains leading space                      
	* <br/>  -21      35  Postal Code has invalid characters                      
	* <br/>  -21      36  Postal Code must be numeric                             
	* <br/>  -21      37  Postal Code invalid length for the United States        
	* <br/>  -21      38  Postal Code invalid length for Canada                   
	* <br/>  -21      39  Postal Code invalid format for Canada.  It must be A#A#A#.                                                              
	* <br/>  -21      41  Country Subdivision contains invalid characters         
	* <br/>  -21      42  Postal Code not determined                              
	* <br/>  -21      43  City not determined                                     
	* <br/>  -21      44  Street not matched                                      
	* <br/>  -21      45  Civic number not found on street, or out of range.      
	* <br/>  -21      46  Street name not found in postal code/municipality.      
	* <br/>  -21      47  Postal code and municipality not found in database.     
	* <br/>  -21      48  No address match was found, and the municipality/       
	* <br/>               province does not agree with the original postal code.  
	* <br/>  -21      49  No address match was found, but the municipality/       
	* <br/>               province agrees with the original postal code.          
	* <br/>  ===================================================================  
	* <br/>                                                                      
	* <br/>  RETURN REASON CODE SPECIFICATION                                    
	* <br/>  -30       1  ZIP+4 Must be Blank for CA                             
	* <br/>  -30       2  Carrier route not determined                           
	* <br/>  -30       3  ZIP+4 not determined                                   
	* <br/>  -30       4  Range not determined                                   
	* <br/>  -30       5  Suffix/directional could not be determined             
	* <br/>  -30       6  Input street could not be matched                      
	* <br/>  -30       7  Firm processing was not successful - there was         
	* <br/>               information on the firm line (FR) but it could not be  
	* <br/>               matched to the address                                 
	* <br/>  -30       8  Secondary address error                                
	* <br/>  -30       9  Non-conventional address not determined                
	* <br/>  =================================================================== 
	* <br/>  NOTE                                                                
	* <br/>  =================================================================== 
	* <br/>  RETURN/REASON CODES                                                 
	* <br/>  -30      10  City type not determined                               
	* <br/>  -30      11  Address type not determined                            
	* <br/>  -30      12  Delivery type not determined                           
	* <br/>  -30      13  All address lines used, address failed                 
	* <br/>  -30      15  Cannot code output address                             
	* <br/>  -30      16  Apartment number missing or not found in database, and 
	* <br/>               an apartment-level match was required.                 
	* <br/>  -30      17  Probable correctness was less than the minimum         
	* <br/>               specified in position 18 of the CONFIG parameter       
	* <br/>               record.                                                
	* <br/>  -30      18  PO Box identifier not found or out of range.           
	* <br/>  -30      19  Route service identifier not found or out of range.    
	* <br/> 
	* <br/>  
	*/
	public validateAddress(request: ValidateAddressRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ValidateAddressResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/address/validate'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Determines whether or not the given SIC code represents an LTL (formerly Con-way Freight Corp.) location. Returns true if it is.  Validates only if it is LTL (CNWY_CORP_CD = 7200) and a physical service center with dock (FRTOPNS_TYP = ï¿½Sï¿½).
	* <br/>The purpose is to validate any SIC code entered into any one of the conversation event forms in OEMA.  The original SIC code comes from the Ops023/Ops030 MSTR report, but once inside OEMA, the user can change that SIC code to any other location.  When they do, all we need to ensure is that it is a valid SIC code for LTL (i.e. the old Conway corp code).
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. Valid SIC.
	* <br/>
	* <br/>Post-condtions:
	* <br/>1. Returns true if it is an LTL Corp SIC.
	*/
	public validateLtlFreightOperationsSic(request: ValidateLtlFreightOperationsSicRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ValidateLtlFreightOperationsSicResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/ltl-corp/{sicCd}'),
			{
		    	pathParams: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Returns location reference information about a service center.
	*/
	public getLocReferenceDetailsBySic(request: GetLocReferenceDetailsBySicRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetLocReferenceDetailsBySicResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/loc-reference-sics/{sicCd}'),
			{
		    	pathParams: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Returns the zone and satellite details for a specific SIC location.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. The SIC code is required.  The Boolean fields are optional.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If the data provided is valid, info about the zone and satellite will be returned.
	* <br/>2. Otherwise an appropriate error message will be returned.
	*/
	public getZoneAndSatelliteBySic(request: GetZoneAndSatelliteBySicRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetZoneAndSatelliteBySicResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/zone-satellite/{sicCd}'),
			{
		    	pathParams: request
				,queryParams: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Determines if Pickup and Delivery business is running for the SIC on the date provided.  
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. Valid SIC and date are provided.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. A true value is returned if PnD service is available on the date specified.
	* <br/>2. False is returned if it isn't available.
	* <br/>3. If the data provided is invalid, an appropriate error message is returned.
	*/
	public isPndAvailable(request: IsPndAvailableRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<IsPndAvailableResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/pnd-availability/{destSic}/{estimatedDeliveryDt}'),
			{
		    	pathParams: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Returns SIC that services the input Postal Code.  Input may contain one or more Postal Codes and the corresponding SIC for each input Postal Code will be returned.
	* <br/>
	* <br/>Pre-condition:
	* <br/>One or more Postal Code values are supplied as input. If Active Flag is not set, it defaults to false.
	* <br/>
	* <br/>Post-condition:
	* <br/>The SIC that services each input Postal Code is returned.  If input Active Flag is set to true, only active service centers are returned.  If Active Flag is set to false (default), active and inactive service centers are returned.
	*/
	public listSicByPostalCd(request: ListSicByPostalCdRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListSicByPostalCdResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/postalassignments'),
			{
				queryParams: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}


	protected getEndPoint(): string {
		return this.configManager.getSetting(LocationApiService.LocationApiEndpoint);
	}
}
