/**
 * Location API
 * Version: 1.0
 * Build: Manual
 */

import {
	Address,
	LatLong,
	ListInfo,
	ServiceCenterLocation,
	DataValidationError,
	Email,
	AuditInfo,
	CountryCd
} from '@xpo-ltl/sdk-common';

/**
 * Request to retrieve detailed information for a service center location.
 */
export class GetServiceCenterDetailsRqst {
	/**
	 * Zipcode to identify the service center
	 */
	zipCd:string = undefined;
}
/**
 * Request to retrieve detailed information for a service center location.
 */
export class GetServiceCenterDetailsBySicRqst {
	/**
	 * The code identifying a given service center
	 */
	sicCd:string = undefined;
}
/**
 * Response that contains detailed information for a service center location.
 */
export class GetServiceCenterDetailsResp {
	/**
	 * The code identifying a given service center
	 */
	sicCd:string = undefined;
	/**
	 * The code identifying the component for the sic
	 */
	componentCd:string = undefined;
	/**
	 * address of service center
	 */
	address:Address = undefined;
	/**
	 * GIS lat long
	 */
	coordinates:LatLong = undefined;
	/**
	 * The offset of the timezone.
	 */
	timzoneOffset:number = undefined;
}
/**
 * Response that contains detailed information for a service center location.
 */
export class GetServiceCenterDetailsBySicResp {
	/**
	 * The code identifying a given service center
	 */
	sicCd:string = undefined;
	/**
	 * The code identifying the component for the sic
	 */
	componentCd:string = undefined;
	/**
	 * address of service center
	 */
	address:Address = undefined;
	/**
	 * GIS lat long
	 */
	coordinates:LatLong = undefined;
	/**
	 * The name of the timezone.
	 */
	timezoneName:string = undefined;
}
/**
 * Request to retrieve the list of sics that have postal assignment changes
 */
export class ListSicsWithPostalAssignmentChangesRqst {
	/**
	 * Zipcode to identify the service center
	 */
	effectiveDate:Date = undefined;
}
/**
 * Response contains the list of sics that have postal assignment changes
 */
export class ListSicsWithPostalAssignmentChangesResp {
	/**
	 * The code identifying a given service center
	 */
	sic:string[] = undefined;
}
/**
 * Request to retrieve the list of postal code assignments associated with the sic
 */
export class ListSicsRqst {
	/**
	 * sic code
	 */
	sic:string = undefined;
	/**
	 * name or partial name describing the sic
	 */
	partialName:string = undefined;
	cityName:string = undefined;
	stateCd:string = undefined;
	countryCd:string = undefined;
	postalCd:string = undefined;
	listInfo:ListInfo = undefined;
}
/**
 * Response contains the list of postal code assignments associated with the sic
 */
export class ListSicsResp {
	/**
	 * The sics that match the input search criteria
	 */
	sics:ServiceCenterLocation[] = undefined;
	listInfo:ListInfo = undefined;
}
/**
 * Request to retrieve the list of postal code assignments associated with the sic
 */
export class ListPostalAssignmentsForSicRqst {
	/**
	 * Zipcode to identify the service center
	 */
	sic:string = undefined;
}
/**
 * Response contains the list of postal code assignments associated with the sic
 */
export class ListPostalAssignmentsForSicResp {
	/**
	 * The postal code assignments for the sic
	 */
	postalCodes:string[] = undefined;
}
/**
 * Request to retrieve detailed information for a service center location.
 */
export class GetSicByFinanceCodesRqst {
	/**
	 * The Oracle Company Code.
	 */
	companyCd:string = undefined;
	/**
	 * The Oracle Profit Center Code.
	 */
	profitCenterCd:string = undefined;
	/**
	 * The Oracle Department Code.
	 */
	departmentCd:string = undefined;
}
/**
 * Response that contains detailed information for a service center location.
 */
export class GetSicByFinanceCodesResp {
	/**
	 * The code for an area, division, district, or service center.
	 */
	sicCd:string = undefined;
	/**
	 * The name for an area, division, district, or service center.
	 */
	sicName:string = undefined;
}
/**
 * Request to find if a given day is a working day or non-working day
 */
export class ListOperationalCalendarDaysRqst {
	startDate:Date = undefined;
	endDate:Date = undefined;
	/**
	 * R(Revenue), W(Weekend), H(Holiday)
	 */
	revenueDayCode:string = undefined;
	/**
	 * For Revenue use 1.0 and 0 for Weekend and Holiday
	 */
	revenueAllocationNbrStr:string = undefined;
	/**
	 * pagination information to retrieve list
	 */
	listInfo:ListInfo = undefined;
}
export class ListOperationalCalendarDaysResp {
	opsCalendarDays:OpsCalendarDay[] = undefined;
	listInfo:ListInfo = undefined;
}
/**
 * Request to find the next business day based on input date and offset number of days
 */
export class GetNextBusinessDayRqst {
	/**
	 * input date
	 */
	startDate:Date = undefined;
	/**
	 * Number of days to add to input date
	 */
	offsetDays:number = undefined;
	/**
	 * Next business date after adding offset days to start date
	 */
	nextBusinessDay:Date = undefined;
}
export class GetNextBusinessDayResp {
	/**
	 * Next business date after adding offset days to start date
	 */
	nextBusinessDay:Date = undefined;
	/**
	 * input date
	 */
	startDate:Date = undefined;
	/**
	 * Number of days to add to input date
	 */
	offsetDays:number = undefined;
}
/**
 * Request to retrieve detailed information for a service center location.
 */
export class GetCustomerServiceCenterDetailsRqst {
	/**
	 * The zip code for a service center.
	 */
	postalCd:string = undefined;
	/**
	 * The unique ID for a service center.
	 */
	sicCd:string = undefined;
}
/**
 * Response that contains detailed information for a service center location.
 */
export class GetCustomerServiceCenterDetailsResp {
	/**
	 * Details about the service center
	 */
	serviceCenter:ServiceCenter = undefined;
}
/**
 * Request message to validate a location address. Address is validated against a vendor provided software.
 */
export class ValidateAddressRqst {
	address:Address = undefined;
}
/**
 * Request message to validate a location address. Address is validated against a vendor provided software.
 */
export class ValidateAddressResp {
	address:Address = undefined;
	/**
	 * Boolean (True/false) field indicating if any validation errors were found during validation. More details will be in the error message section
	 */
	isValid:boolean = undefined;
	dataValidationError:ValidateAddressResp_DataValidationError[] = undefined;
}
/**
 * Request to retrieve the SIC associated with each Postal Code.
 */
export class ListSicByPostalCdRqst {
	/**
	 * Postal Code used to determine the service center
	 */
	postalCodes:string[] = undefined;
	/**
	 * If set to true returns only active service centers.  False returns active and inactive service centers  The Default is false.
	 */
	activeFlag:boolean = undefined;
}
/**
 * Response containing the SIC associated with each Postal Code.
 */
export class ListSicByPostalCdResp {
	/**
	 * List with SIC for each input postal code.
	 */
	locFreightPostalAreas:LocFreightPostalArea[] = undefined;
	validationErrors:DataValidationError[] = undefined;
}
/**
 * Request to validate that a SIC is an LTL one.
 */
export class ValidateLtlFreightOperationsSicRqst {
	/**
	 * The SIC code for a service center.
	 */
	sicCd:string = undefined;
}
/**
 * Response to the request to validate that a SIC is an LTL one.
 */
export class ValidateLtlFreightOperationsSicResp {
	/**
	 * True if SIC is LTL (Con-way Freight Corp); false, otherwise.
	 */
	isLltlCorpSic:boolean = undefined;
}
/**
 * Request to retrieve location reference details for a service center location.
 */
export class GetLocReferenceDetailsBySicRqst {
	/**
	 * The code identifying a given service center
	 */
	sicCd:string = undefined;
}
/**
 * Response that contains location reference detail information for a service center location.
 */
export class GetLocReferenceDetailsBySicResp {
	locReference:LocReference = undefined;
	locCompanyOperations:LocCompanyOperations = undefined;
	locConWayCorporation:LocConwayCorporation = undefined;
	chargeToSicCd:string = undefined;
	chargeToSicNm:string = undefined;
	areaSicCd:string = undefined;
	regionSicCd:string = undefined;
	satelliteParentSicCd:string = undefined;
	satelliteParentSicNm:string = undefined;
	zoneParentSicCd:string = undefined;
	zoneParentSicNm:string = undefined;
	linehaulHostSicCd:string = undefined;
	linehaulHostSicNm:string = undefined;
	regionLocHierarchyGroup:LocHierarchyGroup = undefined;
	areaLocHierarchyGroup:LocHierarchyGroup = undefined;
}
/**
 * Request to return zone and satellite details for a specific SIC location.
 */
export class GetZoneAndSatelliteBySicRqst {
	/**
	 * The SIC code for a service center.
	 */
	sicCd:string = undefined;
	/**
	 * The true/false indicator denoting if the zone should be returned.
	 */
	zoneInd:boolean = undefined;
	/**
	 * The true/false indicator denoting if the satellite should be returned.
	 */
	satelliteInd:boolean = undefined;
}
/**
 * Response to the request to return zone and satellite details for a specific SIC location.
 */
export class GetZoneAndSatelliteBySicResp {
	/**
	 * The SIC code for a service center.
	 */
	zoneSatelliteInfo:ZoneSatelliteInfo[] = undefined;
	/**
	 * The SIC used in the search (same as the input SIC).
	 */
	refSic:string = undefined;
	/**
	 * The region for the import SIC (host region for the sic).
	 */
	refRegionSic:string = undefined;
	/**
	 * The host of the import SIC if the import SIC was a zone.
	 */
	refZoneSic:string = undefined;
	/**
	 * The host of the import SIC if the import SIC was a satellite.
	 */
	refSatelliteSic:string = undefined;
}
/**
 * Request to determine if Pickup and Delivery business is running for the SIC on the date provided.
 */
export class IsPndAvailableRqst {
	/**
	 * The SIC that is being checked for PnD availability on the date requested.
	 */
	destSic:string = undefined;
	/**
	 * The date requested for PnD availability.
	 */
	estimatedDeliveryDt:Date = undefined;
}
/**
 * Response to the request to determine if Pickup and Delivery business is running for the SIC on the date provided.
 */
export class IsPndAvailableResp {
	/**
	 * The date requested for PnD availability.
	 */
	isAvailable:boolean = undefined;
}
/**
 * Information about Operational days for US and CA
 */
export class OpsCalendarDay {
	calDate:Date = undefined;
	/**
	 * R=Work Day, W=Weekend, H= Holiday
	 */
	revenueDayCode:string = undefined;
	/**
	 * 1.0 for R, 0 for W and H
	 */
	revenueAllocationNumber:number = undefined;
	/**
	 * If true then the date is a non-work day for Canada. If false then its a work day
	 */
	caNonWorkDayInd:boolean = undefined;
	/**
	 * If true then the date is a non-work day for US. If false then its a work day
	 */
	usNonWorkDayInd:boolean = undefined;
	financialReportDate:Date = undefined;
}
/**
 * Information about the Service Center
 */
export class ServiceCenter {
	/**
	 * The SIC that provides service to the area identified by the provided zip code.
	 */
	sicCd:string = undefined;
	/**
	 * The address of the service center.
	 */
	address:Address = undefined;
	/**
	 * The GIS coordinates of the service center.
	 */
	coordinates:LatLong = undefined;
	/**
	 * The phone number of customer service at the service center.
	 */
	custServicePhoneNbr:string = undefined;
	/**
	 * The toll-free phone number for the service center.
	 */
	tollFreePhoneNbr:string = undefined;
	/**
	 * The fax telephone number for the service center.
	 */
	faxNbr:string = undefined;
	/**
	 * Contains the names and contact information for staff at the service center.
	 */
	contact:CustomerContactInfoBasic[] = undefined;
}
/**
 * Basic contact information about customers.
 */
export class CustomerContactInfoBasic {
	/**
	 * The job title of the contact.
	 */
	contactTitle:string = undefined;
	/**
	 * The full name of the contact.
	 */
	contactFullName:string = undefined;
	/**
	 * The email address which is used to communicate with the contact.
	 */
	email:Email = undefined;
}
/**
 * 
 */
export class LocCompanyOperations {
	/**
	 * 
	 */
	refSicCd:string = undefined;
	/**
	 * 
	 */
	rsiOperationsTypeCd:string = undefined;
	/**
	 * 
	 */
	truckloadOperationsTypeCd:string = undefined;
	/**
	 * 
	 */
	menloOperationsTypeCd:string = undefined;
	/**
	 * 
	 */
	hostRefSicCd:string = undefined;
	/**
	 * 
	 */
	companyNmCd:string = undefined;
	/**
	 * 
	 */
	classSizeCd:string = undefined;
	/**
	 * 
	 */
	dependentFreightTypeCd:string = undefined;
	/**
	 * 
	 */
	facHoursOfOperationCd:string = undefined;
	/**
	 * 
	 */
	freightOperationsTypeCd:string = undefined;
	/**
	 * 
	 */
	freightServiceAreaTypeCd:string = undefined;
	/**
	 * 
	 */
	intermodalTypeCd:string = undefined;
	/**
	 * 
	 */
	safetyClassSizeCd:string = undefined;
	/**
	 * 
	 */
	satelliteTypeCd:string = undefined;
	/**
	 * 
	 */
	serviceSatelliteTypeCd:string = undefined;
	/**
	 * 
	 */
	maintenanceTypeCd:string = undefined;
	/**
	 * 
	 */
	workDoorCnt:string = undefined;
	/**
	 * 
	 */
	cityOperationsInd:boolean = undefined;
	/**
	 * 
	 */
	operations24HourInd:boolean = undefined;
	/**
	 * 
	 */
	documentImageInd:boolean = undefined;
	/**
	 * 
	 */
	dispatchToInd:boolean = undefined;
	/**
	 * 
	 */
	dependentSicInd:boolean = undefined;
	/**
	 * 
	 */
	facInd:boolean = undefined;
	/**
	 * 
	 */
	freightOperationsInd:boolean = undefined;
	/**
	 * 
	 */
	freightServiceAreaInd:boolean = undefined;
	/**
	 * 
	 */
	fuelInd:boolean = undefined;
	/**
	 * 
	 */
	hostedSicInd:boolean = undefined;
	/**
	 * 
	 */
	loadToInd:boolean = undefined;
	/**
	 * 
	 */
	liftInd:boolean = undefined;
	/**
	 * 
	 */
	linehaulDayMeetInd:boolean = undefined;
	/**
	 * 
	 */
	linehaulNightMeetInd:boolean = undefined;
	/**
	 * 
	 */
	rrSpurInd:boolean = undefined;
	/**
	 * 
	 */
	shipmentOriginDestinationInd:boolean = undefined;
	/**
	 * 
	 */
	terminalProdInd:boolean = undefined;
	/**
	 * 
	 */
	terminalStmsLocInd:boolean = undefined;
	/**
	 * 
	 */
	tieInd:boolean = undefined;
	/**
	 * 
	 */
	warmRoomInd:boolean = undefined;
	/**
	 * 
	 */
	yardMeetAndTurnInd:boolean = undefined;
	/**
	 * 
	 */
	freightLtlMovementInd:boolean = undefined;
	/**
	 * 
	 */
	freightLtlMaintenanceInd:boolean = undefined;
	/**
	 * 
	 */
	directions:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class LocConwayCorporation {
	/**
	 * 
	 */
	conwayCorpCd:string = undefined;
	/**
	 * 
	 */
	printConwayCorpCd:string = undefined;
	/**
	 * 
	 */
	corpNm:string = undefined;
	/**
	 * 
	 */
	altCorpCd:string = undefined;
	/**
	 * 
	 */
	componentCd:string = undefined;
	/**
	 * 
	 */
	componentNm:string = undefined;
	/**
	 * 
	 */
	countryCd:string = undefined;
	/**
	 * 
	 */
	companyLogoCd:string = undefined;
	/**
	 * 
	 */
	glCorpCd:string = undefined;
	/**
	 * 
	 */
	scacCd:string = undefined;
	/**
	 * 
	 */
	sltCorp3CharNm:string = undefined;
	/**
	 * 
	 */
	sobCd:string = undefined;
	/**
	 * 
	 */
	companyNmCd:string = undefined;
	/**
	 * 
	 */
	sltT60MajCorpCd:string = undefined;
	/**
	 * Unique identifier
	 */
	instanceId:number = undefined;
	/**
	 * Active Indicator
	 */
	activeInd:boolean = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class LocHierarchyGroup {
	/**
	 * 
	 */
	hierarchyGroupRefSicCd:string = undefined;
	/**
	 * 
	 */
	hierarchyGroupName:string = undefined;
	/**
	 * 
	 */
	hierarchyCategoryCd:string = undefined;
	/**
	 * 
	 */
	sltAreaCd:string = undefined;
	/**
	 * 
	 */
	sltNameA:string = undefined;
	/**
	 * 
	 */
	sltNameB:string = undefined;
	/**
	 * 
	 */
	sltRegionCd:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class LocReference {
	/**
	 * The SIC belongs to a Region/Division which belongs to an Area. This is the Area of the Region to which the SICis associated. It is denormed from the Hierarchy tables.
	 */
	areaSicCd:string = undefined;
	/**
	 * The SIC that handles charges for this SIC.
	 */
	chargeRefSicCd:string = undefined;
	/**
	 * The Corporation code for this SIC.
	 */
	conwayCorpCd:string = undefined;
	/**
	 * ERP Company code for this SIC.
	 */
	erpCompanyCd:string = undefined;
	/**
	 * ERP Department code for this SIC.
	 */
	erpDepartmentCd:string = undefined;
	/**
	 * ERP Profit Center code for this SIC.
	 */
	erpProfitCenterCd:string = undefined;
	/**
	 * The identifier for a Function.Examples:00 - Balance Sheet02 - Linehaul Operations03 - Domestic Operations98 - TaxSource:Slt_Tbl504.M07_Oracle_Function
	 */
	finFunctionCd:string = undefined;
	/**
	 * Second Function Code included for Standard Location Table requirements.
	 */
	finSecondFunctionCd:string = undefined;
	/**
	 * SIC code where mail is delivered for this SIC.
	 */
	mailRefSicCd:string = undefined;
	/**
	 * Code for the mail stop for this SIC which may be an Address that is a Physical Site rather than a Post Office Box
	 */
	mailStopCd:string = undefined;
	/**
	 * MRC (Management Report Code) is used by some of the older systems for grouping sics together for report purposes.
	 */
	mrcCd:string = undefined;
	/**
	 * Oracle Area Division SIC cd.
	 */
	oracleAreaDivisionSicCd:string = undefined;
	/**
	 * Indicates whether the facility is owned or leased. Value Meaning O Owned  L Leased blank Indeterminate or it doesn't matter (null) ZZZ unassigned Sic
	 */
	ownOrLeaseCd:string = undefined;
	/**
	 * Type of LocationValue MeaningN Non Physical LocationP Physical Location
	 */
	physicalLocationTypeCd:string = undefined;
	/**
	 * Reference SIC cd identifying this SIC.
	 */
	refSicCd:string = undefined;
	/**
	 * This defines the high level SIC type:O = Company OperationsD = AdministrationA = Accounting
	 */
	refSicScpTypeCd:string = undefined;
	/**
	 * Region code for this SIC.
	 */
	regionSicCd:string = undefined;
	/**
	 * A code used by Safety to record information abouta Freight Service Center.
	 */
	safetyClassSizeCd:string = undefined;
	/**
	 * Identifies the location that handles scanning for this SIC.
	 */
	scanLocationSicCd:string = undefined;
	/**
	 * The name assigned to this SIC
	 */
	sicNm:string = undefined;
	/**
	 * The terminal's two character state abbreviation.
	 */
	sltStateAbbreviation:string = undefined;
	/**
	 * Short version of the terminal name from the SLT table.
	 */
	sltTerminalAbbreviation:string = undefined;
	/**
	 * Name of the timezone the sic follows.
	 */
	timezoneName:string = undefined;
	/**
	 * Category code used by truckload
	 */
	truckloadCategoryCd:string = undefined;
	/**
	 * Legacy Account Number used by truckload. This field gives them the ability to tie a SIC to an Account.
	 */
	truckloadXrefCd:string = undefined;
	/**
	 * Two digit country code from the standard location table
	 */
	sltCountryCd:string = undefined;
	/**
	 * A composite field used for sorting
	 */
	sltPrimarySequence:string = undefined;
	/**
	 * From the Standard Location Table - Indicates thatthe Location is a Corporate Office.
	 */
	corportateOfficeInd:boolean = undefined;
	/**
	 * Daylight Savings Indicator.  Indicates whether the location observes daylight savings time or not.
	 */
	daylightSavingsTimeInd:boolean = undefined;
	/**
	 * Indicates that the Sic is a Book Closing Locationand is referenced in the Oracle Financial system.
	 */
	finSicInd:boolean = undefined;
	/**
	 * This Reference is used to group other sics intosome kind of a hierarchical group.
	 */
	hierarchyGroupInd:boolean = undefined;
	/**
	 * Indicates that the Kronos system is used to keeptrack of hours worked at the location. Normallythis will be for a city operations center or afreight assembly center.It may be a warehouse in the future.
	 */
	kronosInd:boolean = undefined;
	/**
	 * SIC is used for mileage tracking purposes
	 */
	mileageInd:boolean = undefined;
	/**
	 * Indicates that the Operations Begin Date and theOperations End Date as well as information aboutthe Sic itself comprise privileged information.
	 */
	privateInd:boolean = undefined;
	/**
	 * Identifies a Location that can handle scanning for other Locations
	 */
	scanLocationInd:boolean = undefined;
	/**
	 * Indicates whether this SIC is used for sales
	 */
	salesSicInd:boolean = undefined;
	/**
	 * Unique identifier
	 */
	instanceId:number = undefined;
	/**
	 * Effective date of the location reference
	 */
	effDate:Date = undefined;
	/**
	 * Expiration date of the location reference
	 */
	exprDate:Date = undefined;
	/**
	 * The date that operations actually begins for somelocations. This is different from the EffectiveDate of the Reference.
	 */
	operationsBeginDate:Date = undefined;
	/**
	 * The date that operations ends for some locations.This is different from the Expiration Date of theReference.
	 */
	operationsEndDate:Date = undefined;
	/**
	 * If there is a lease, this is its expiration date.If the Own Or Lease Code is blank or 'O' the dateis probably arbitrary.
	 */
	leaseExprDt:Date = undefined;
	/**
	 * Identifies the date when a SIC became, or will become, a SCAN SIC and starts performing that function for other SICs.
	 */
	scanDate:Date = undefined;
	/**
	 * Identifier of the location where mal is delivered for this SIC.
	 */
	mailAddressId:number = undefined;
	/**
	 * Time offset from Portland time
	 */
	pdtTimeOffsetNbr:string = undefined;
	/**
	 * Indicates whether time offset is needed from Pacific time
	 */
	pdtTimeOffsetInd:boolean = undefined;
	/**
	 * Denormed from foreign key PHYS_SITE_LATD on GEO_ADDRESS from relationship to GEO_PLACE.
	 */
	physicalSiteLatitude:number = undefined;
	/**
	 * Denormed from foreign key PHYS_SITE_LNGT on GEO_ADDRESS from relationship to GEO_PLACE.
	 */
	physicalSiteLongitude:number = undefined;
	/**
	 * The date this location began accepting mail for other locations.
	 */
	mailLocationAddressEffDate:Date = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * Details about zone and satellite.
 */
export class ZoneSatelliteInfo {
	/**
	 * The SIC code for the region.
	 */
	regionSic:string = undefined;
	/**
	 * The SIC code for the location.
	 */
	locationSic:string = undefined;
	/**
	 * The parent satellite.
	 */
	satelliteParent:string = undefined;
	/**
	 * The zone location of the terminal.
	 */
	zoneTerminal:string = undefined;
}
/**
 * 
 */
export class LocFreightPostalArea {
	/**
	 * The SIC that provides service to the area identified by the provided zip code.
	 */
	sicCd:string = undefined;
	/**
	 * Unique identifier for a Country.
	 */
	countryCd:CountryCd = undefined;
	/**
	 * The identifier that distinguishes one Country Subdivision from another within a Country. This represents the ISO standard code for a CountrySubdivision
	 */
	countrySubdivisionCd:string = undefined;
	/**
	 * The identifier for the Geographic Postal Areathat is included as part of the Freight Service Area.
	 */
	geoPostalCd:string = undefined;
	/**
	 * Indicates whether this Geographic Postal Area isan active part of this Freight Service Area.
	 */
	activeFlag:boolean = undefined;
	/**
	 * Some Postal Areas do not get direct service dueto distance or inacessability. Freight to Customers in these areascannot have guaranteed delivery because there may not be daily schedules to indirect postal areas.  These areas are normally served by interlinecarriers.
	 */
	dirFlag:boolean = undefined;
	/**
	 * The last day that the link between GeographicPostal Area and Freight Service Area is active.
	 */
	expirationDt:Date = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 */
	auditInfo:AuditInfo = undefined;
}
export class ValidateAddressResp_DataValidationError extends DataValidationError {
	errorReasonCd:string = undefined;
}

