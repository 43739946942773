
export enum AccessorialUnitOfMeasureCd {
	CHECK = 'Check',
	CORRECTION = 'Correction',
	CWT = 'Cwt',
	CWT_DAY = 'CwtDay',
	DAY = 'Day',
	DOCUMENT = 'Document',
	HALF_HR = 'HalfHr',
	LABOUR_HR = 'LabourHr',
	MILE = 'Mile',
	PACKAGE = 'Package',
	POUND = 'Pound',
	QUARTER_HR = 'QuarterHr',
	SHIPMENT = 'Shipment',
	VEHICLE = 'Vehicle'}

