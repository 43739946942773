/**
 * AccountsReceivable API
 * Version: 1.0
 * Build: Manual
 */


export class ListArPaymentDetailsByProRqst {
	proNbr:string = undefined;
	pickupDate:string = undefined;
}
export class ListArPaymentDetailsByProResp {
	arPaymentDetails:ArPaymentDetail[] = undefined;
}
export class ListArPaymentSummaryByProRqst {
	proNbr:string = undefined;
	pickupDate:string = undefined;
}
export class ListArPaymentSummaryByProResp {
	arPaymentSummaries:ArPaymentSummary[] = undefined;
}
export class ArPaymentDetail {
	proNbr:string = undefined;
	currencyCd:string = undefined;
	custNbr:string = undefined;
	receiptNbr:string = undefined;
	chargeCd:string = undefined;
	paymentDate:Date = undefined;
	totalChargeAmt:number = undefined;
	totalPaymentAmt:number = undefined;
	totalAdjustmentAmt:number = undefined;
	balanceDueAmt:number = undefined;
	creditedAmt:number = undefined;
	inDisputeAmt:number = undefined;
}
export class ArPaymentSummary {
	proNbr:string = undefined;
	totalChargeAmt:number = undefined;
	totalPaymentAmt:number = undefined;
	totalAdjustmentAmt:number = undefined;
	balanceDueAmt:number = undefined;
	creditedAmt:number = undefined;
	inDisputeAmt:number = undefined;
	chargeCd:string = undefined;
	proStatusCd:string = undefined;
	paymentStatusTxt:string = undefined;
}

/**
 * Query parameters for listArPaymentDetailsByPro
 */
export class ListArPaymentDetailsByProQuery {
	proNbr: string = undefined;
	pickupDate: string = undefined;
}

/**
 * Query parameters for listArPaymentSummaryByPro
 */
export class ListArPaymentSummaryByProQuery {
	proNbr: string = undefined;
	pickupDate: string = undefined;
}



