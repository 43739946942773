
export enum RuleOperatorCd {
	EQUAL_TO = 'EqualTo',
	GREATER_THAN = 'GreaterThan',
	GREATER_THAN_OR_EQUAL = 'GreaterThanOrEqual',
	IN = 'In',
	LESS_THAN = 'LessThan',
	LESS_THAN_OR_EQUAL = 'LessThanOrEqual',
	MATCHES = 'Matches',
	NOT_EQUAL_TO = 'NotEqualTo',
	NOT_IN = 'NotIn'}

