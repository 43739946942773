
export enum EquipmentTypeCd {
	DOLLY = 'Dolly',
	L_H_TRAILER = 'L/HTrailer',
	OTHER = 'Other',
	P_D_TRAILER = 'P+DTrailer',
	RENTAL = 'Rental',
	STORAGE_TRAILER = 'StorageTrailer',
	STRIGHT_TRUCK = 'StrightTruck',
	TRACTOR = 'Tractor',
	VAN = 'Van'}

