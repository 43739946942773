
export enum ServiceCalculationStatusCd {
	ALL_SHORT = 'AllShort',
	APPT = 'Appt',
	ATTEMPTED = 'Attempted',
	CANNOT_CALCULATE = 'CannotCalculate',
	CARTAGE = 'Cartage',
	DAMAGED = 'Damaged',
	FINAL = 'Final',
	HOLD_FOR_CUSTOMS = 'HoldForCustoms',
	NOT_CALC = 'NotCalc',
	OVER_SHPMT = 'OverShpmt',
	PARTIAL_SHORT = 'PartialShort',
	PRG_BLOCKED = 'PrgBlocked',
	REFUSED = 'Refused',
	RETURNED = 'Returned',
	SPOTTED = 'Spotted',
	SUSPENSE = 'Suspense',
	TRANSFER = 'Transfer',
	TRAPPED = 'Trapped',
	UNDELIVERED = 'Undelivered',
	WAIT = 'Wait'}

