
export enum ShipmentSpecialServiceCd {
	DRIVER_COLLECT = 'DriverCollect',
	GUARANTEED = 'Guaranteed',
	GUARANTEED_BY_NOON = 'GuaranteedByNoon',
	HAZMAT = 'Hazmat',
	LIFT_GATE = 'LiftGate',
	NOTIFY_ON_ARRIVAL = 'NotifyOnArrival',
	TIME_DATE_CRITICAL = 'TimeDateCritical',
	UNMAPPED = 'Unmapped'}

