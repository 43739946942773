import { Injectable } from '@angular/core';
import { DataApiService, DataOptions, HttpOptions, BaseService } from '@xpo-ltl/data-api';
import { ConfigManagerService } from '@xpo-ltl/config-manager';

import {
	ListArPaymentDetailsByProResp,
	ListArPaymentDetailsByProQuery,
	ListArPaymentSummaryByProResp,
	ListArPaymentSummaryByProQuery
} from './api-accountsreceivable';

import {
	GetHealthCheckResp,
	User
} from '@xpo-ltl/sdk-common';

import { Observable } from 'rxjs';

@Injectable()
export class AccountsReceivableApiService extends BaseService {
	private static AccountsReceivableApiEndpoint = 'accountsreceivableApiEndpoint';

	constructor(private dataApiService: DataApiService, private configManager: ConfigManagerService) {
		super();
	}

	/**
	* Health check URL. Responds with success message if the service is running.
	*/
	public healthCheck(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetHealthCheckResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/health-check'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* List of resources.
	*/
	public options(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/options'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Details of user invoking the API.
	*/
	public loggedInUser(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<User> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/appusers/logged-in-user'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This service operation will retrieve AR details by pro number.
	*/
	public listArPaymentDetailsByPro(
							   queryParams: ListArPaymentDetailsByProQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListArPaymentDetailsByProResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/arpayments/details'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This service operation will retrieve AR summary lines by pro number.
	*/
	public listArPaymentSummaryByPro(
							   queryParams: ListArPaymentSummaryByProQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListArPaymentSummaryByProResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/arpayments/summaries'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}


	protected getEndPoint(): string {
		return this.configManager.getSetting(AccountsReceivableApiService.AccountsReceivableApiEndpoint);
	}
}
