import {MetricScoreCd} from '../';
import {EnumHelper} from './enum-helper';

export class MetricScoreCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in MetricScoreCd ) {
      this._values.push(MetricScoreCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): MetricScoreCd {

    for ( const obj in MetricScoreCd ) {
      if (MetricScoreCd[obj] === value){
        return MetricScoreCd[obj] as MetricScoreCd;
      }
    }
  }
}

const MetricScoreCdHelper = new MetricScoreCdHelperEnumHelperClass();
export default MetricScoreCdHelper;
